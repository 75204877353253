<template>
  <div>
    <div v-if="!loadingPage" style="display:flex;justify-content:center;min-height:100vh;width:100vw;"  class="landing-wrapper" >
      <div class="text-center" style="display: flex;align-items: center;flex-direction: column;justify-content: center;">
        <h1 class="text-danger">Email Verified</h1>
        <h4>Thank You</h4>
      </div>
    </div>
    <div v-else class="d-flex justify-content-center align-items-center vh-100">
      <b-spinner></b-spinner>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadingPage: false,
      token: this.$route.params.token,
    }
  },
  mounted() {
    this.getPage()
    document.body.style.paddingBottom = 0
  },

  methods: {
    getPage() {
      if (this.$route.query && this.$route.query.redirectURL) {
        this.loadingPage = true
      }
      this.$store.dispatch('landing/verifyEmailToken', this.$route.params.token)
        .then(() => {
          if (this.$route.query && this.$route.query.redirectURL) {
            const redirectURL = this.$route.query.redirectURL;
            // Validate redirectURL (optional, for security purposes)
            try {
              // Perform redirection
              window.location.href = redirectURL;
            } catch (error) {
              console.error('Invalid redirect URL', error);
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
}
</script>
<style lang="scss" scoped>

</style>
